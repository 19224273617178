import { useState, useContext, useEffect } from "react";
import {
  Button,
  Form,
  Modal,
  ToggleButton,
  ButtonGroup,
  Spinner
} from "react-bootstrap";
import {
  DataGridPro,
  GridSelectionModel,
  GridRowParams
} from "@mui/x-data-grid-pro";
import Error from "../abstractComponents/error";
import { PmxActivity } from "../../backend/types";
import { useEditPmxActivityMutation } from "../../backend/hooks/pmxActivity/mutationEditActivity";
import { PmxActivityDetailsContext } from "../../contexts/pmxActivityDetailsProvider";
import { useGetMyPmxActivitiesQuery } from "../../backend/hooks/pmxActivity/queryGetMyPmxActivities";
import { useUserHelper } from "../../hooks/userHelper";
import { useAuth } from "../authContextProvider";

interface EditActivityModalProps {
  isEditWindowOpen: boolean;
  setIsEditWindowOpen: Function;
  refetchRepoObjects: Function;
  activityData?: PmxActivity | undefined | null;
}

export default function EditActivityModal(props: EditActivityModalProps) {
  const [readOnlyRepoIds, setReadOnlyRepoIds] =
    useState<GridSelectionModel | null>(null);
  const [originalReadOnlyRepositories, setOriginalReadOnlyRepositories] =
    useState<[string]>([""]);
  const { activityData, activityId, refetchActivityDetails } = useContext(
    PmxActivityDetailsContext
  );
  const actualActivityId = activityId ? activityId : props.activityData?.id;

  let activityDetails: any;
  if (activityData && activityData?.trialNumber) {
    activityDetails = {
      trialNumber: activityData.trialNumber,
      bayNumber: activityData.bayNumber ? activityData.bayNumber : "",
      projectId: activityData.projectId ? activityData.projectId : "",
      trialShortDescription: activityData.trialShortDescription,
      isStandard: activityData.mainRepository.isStandard,
      readOnlyRepositories: activityData.readOnlyRepositories
    };
  }

  if (props.activityData && props.activityData?.trialNumber) {
    activityDetails = {
      trialNumber: props.activityData.trialNumber,
      bayNumber: props.activityData.bayNumber
        ? props.activityData.bayNumber
        : "",
      projectId: props.activityData.projectId
        ? props.activityData.projectId
        : "",
      trialShortDescription: props.activityData.trialShortDescription,
      isStandard: props.activityData.mainRepository.isStandard,
      readOnlyRepositories: props.activityData.readOnlyRepositories
    };
  }

  const [newActivityDetails, setNewActivityDetails] = useState(activityDetails);
  const [isTrialNumberInvalid, setIsTrialNumberInvalid] = useState(false);
  const [isBayNumberInvalid, setIsBayNumberInvalid] = useState(false);
  const [isProjectIdInvalid, setIsProjectIdInvalid] = useState(false);
  const [trialNumberError, setTrialNumberError] = useState("");
  const [bayNumberError, setBayNumberError] = useState("");
  const [projectIdError, setProjectIdError] = useState("");
  const { isDataAccessManager } = useAuth();

  const { activities, activitesLoading } = useGetMyPmxActivitiesQuery(
    !props.isEditWindowOpen
  );

  const alphaNumericRegex = /[^a-zA-Z0-9.:\\+-=@_/ ]/;
  const { isBayerUser } = useUserHelper();

  function validateAlphaNumeric(
    value: string,
    setInvalid: React.Dispatch<React.SetStateAction<boolean>>,
    setError: React.Dispatch<React.SetStateAction<string>>
  ) {
    if (value.match(alphaNumericRegex)) {
      setInvalid(true);
      setError(
        "Must only contain letters, numbers, spaces or the following symbols: .:+=@_/"
      );
    } else if (value.startsWith("aws")) {
      setInvalid(true);
      setError("Must not start with 'aws'.");
    } else {
      setInvalid(false);
    }
  }

  useEffect(() => {
    if (
      activityData?.readOnlyRepositories &&
      activityData?.readOnlyRepositories.length > 0
    ) {
      const repoArray = activityData?.readOnlyRepositories?.map(
        (repo) => repo?.id
      );
      setReadOnlyRepoIds(repoArray as GridSelectionModel);
      setOriginalReadOnlyRepositories(repoArray as [string]);
    }
  }, [activities]);

  const { editActivity, isLoadingMutation, errorMutation } =
    useEditPmxActivityMutation();

  function editActivityCall() {
    const variables = {
      ...newActivityDetails,
      id: actualActivityId,
      readOnlyRepositories:
        readOnlyRepoIds && readOnlyRepoIds.map((id) => Number(id))
    };
    editActivity({
      variables
    }).then(() => {
      props.setIsEditWindowOpen(false);
      props.refetchRepoObjects({ actualActivityId, forceRefresh: true });
      if (refetchActivityDetails) {
        refetchActivityDetails();
      }
    });
  }

  const updateActivityStandard = (value: any) => {
    setNewActivityDetails((prevState: any) => {
      prevState["isStandard"] = value;
      return {
        ...prevState
      };
    });
  };

  const updateActivityDetails = (event: any, keyName: string) => {
    setNewActivityDetails((prevState: any) => {
      prevState[keyName] = event;
      return {
        ...prevState
      };
    });

    switch (keyName) {
      case "trialNumber":
        event = event.trim();
        validateAlphaNumeric(
          event,
          setIsTrialNumberInvalid,
          setTrialNumberError
        );
        break;
      case "bayNumber":
        event = event.trim();
        validateAlphaNumeric(event, setIsBayNumberInvalid, setBayNumberError);
        break;
      case "projectId":
        event = event.trim();
        validateAlphaNumeric(event, setIsProjectIdInvalid, setProjectIdError);
        break;
    }
  };

  const cancelAction = () => {
    setNewActivityDetails(activityDetails);
    props.setIsEditWindowOpen(false);
  };

  return (
    <>
      <Modal
        show={props.isEditWindowOpen}
        onHide={() => props.setIsEditWindowOpen(false)}
        dialogClassName="modal-75w"
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit {activityData?.trialNumber} Activity</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mb-3" controlId="formBasicName">
            <Form.Label>
              Activity Name <span className="red_form">*</span>
            </Form.Label>
            <Form.Control
              type="text"
              required
              isInvalid={isTrialNumberInvalid}
              placeholder="Enter activity name"
              value={newActivityDetails?.trialNumber}
              onChange={(e) =>
                updateActivityDetails(e.target.value, "trialNumber")
              }
              disabled={!isBayerUser}
            />
            <Form.Control.Feedback type="invalid">
              {trialNumberError}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group
            className="mb-3"
            data-testid="formBasicBayNumber"
            controlId="formBasicBayNumber"
          >
            <Form.Label>BayNumber</Form.Label>
            <Form.Control
              type="text"
              required
              isInvalid={isBayNumberInvalid}
              placeholder="Enter BayNumber"
              value={
                newActivityDetails?.bayNumber
                  ? newActivityDetails?.bayNumber
                  : ""
              }
              onChange={(e) =>
                updateActivityDetails(e.target.value, "bayNumber")
              }
              disabled={!isBayerUser}
            />
            <Form.Control.Feedback type="invalid">
              {bayNumberError}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicProjectId">
            <Form.Label>Project Id</Form.Label>
            <Form.Control
              type="text"
              required
              isInvalid={isProjectIdInvalid}
              placeholder="Enter Project ID"
              value={
                newActivityDetails?.projectId
                  ? newActivityDetails?.projectId
                  : ""
              }
              onChange={(e) =>
                updateActivityDetails(e.target.value, "projectId")
              }
              disabled={!isBayerUser}
            />
            <Form.Control.Feedback type="invalid">
              {projectIdError}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3" controlId="formTrialShortDescription">
            <Form.Label>Activity Short Description</Form.Label>
            <Form.Control
              as="textarea"
              type="text"
              required
              placeholder="Enter Activity Short Description"
              value={
                newActivityDetails?.trialShortDescription
                  ? newActivityDetails?.trialShortDescription
                  : ""
              }
              onChange={(e) =>
                updateActivityDetails(e.target.value, "trialShortDescription")
              }
              disabled={!isBayerUser}
            />
          </Form.Group>

          {isBayerUser && !props.activityData && (
            <Form.Group
              className="mb-3"
              key="readOnlyRepositories"
              controlId="readOnlyRepositories"
            >
              <Form.Label>Read only PMX Activities attached</Form.Label>
              <DataGridPro
                rows={
                  activities
                    ? activities?.getMyPmxActivities.filter(
                        (activity: PmxActivity) =>
                          activity.mainRepository.isStandard &&
                          activityData?.id !== activity.id
                      )
                    : []
                }
                columns={[
                  {
                    field: "trialNumber",
                    headerName: "Trial Number",
                    width: 300
                  },
                  { field: "bayNumber", headerName: "BayNumber", width: 200 },
                  {
                    field: "projectId",
                    headerName: "Project ID",
                    width: 200
                  },
                  {
                    field: "trialShortDescription",
                    headerName: "Trial Description",
                    width: 150
                  }
                ]}
                rowHeight={60}
                pageSize={10}
                selectionModel={readOnlyRepoIds ? readOnlyRepoIds : [""]}
                getRowId={(row) => row.mainRepository.id}
                onSelectionModelChange={(newSelectionModel) => {
                  setReadOnlyRepoIds(newSelectionModel);
                }}
                autoHeight
                checkboxSelection
                className="choose_read_only_repos"
                isRowSelectable={(params: GridRowParams) =>
                  !originalReadOnlyRepositories.includes(
                    String(params.row.mainRepository.id)
                  )
                }
                loading={activitesLoading}
              />
            </Form.Group>
          )}
          {isDataAccessManager &&
            !activityData?.mainRepository?.isStandard &&
            !props.activityData?.mainRepository?.isStandard && (
              <ButtonGroup>
                <ToggleButton
                  type="radio"
                  variant={"outline-primary"}
                  name="isNewRepositoryStandard"
                  id="standardPublicRepository"
                  data-testid="isNewRepositoryStandard"
                  value="true"
                  checked={newActivityDetails?.isStandard}
                  onClick={() => updateActivityStandard(true)}
                  disabled={!isBayerUser}
                >
                  Standard PMx public
                </ToggleButton>
                <ToggleButton
                  type="radio"
                  variant={"outline-primary"}
                  name="isNewRepositoryStandard"
                  id="accessRestrictedRepository"
                  value="false"
                  checked={!newActivityDetails?.isStandard}
                  onClick={() => updateActivityStandard(false)}
                >
                  Access restricted
                </ToggleButton>
              </ButtonGroup>
            )}
          <br />
        </Modal.Body>

        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={cancelAction}
            disabled={isLoadingMutation}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            disabled={
              newActivityDetails?.trialNumber === "" ||
              isLoadingMutation ||
              isTrialNumberInvalid ||
              isBayNumberInvalid ||
              isProjectIdInvalid ||
              !isBayerUser
            }
            onClick={editActivityCall}
          >
            {isLoadingMutation ? (
              <>
                {" "}
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  className="spinner_color"
                />{" "}
                Saving
              </>
            ) : (
              "Save Changes"
            )}
          </Button>
        </Modal.Footer>
      </Modal>
      {errorMutation && <Error error={errorMutation} />}
    </>
  );
}
