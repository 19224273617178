import { Card, CardContent, Typography } from "@mui/material";
import { Job } from "../../backend/executionTypes";
import {
  convertLocalTimeZone,
  getStatusIcon,
  getStatusTitle
} from "./executionHelpers";
import { Col, Row } from "react-bootstrap";
import Chip from "@mui/material/Chip";
import { Link } from "react-router-dom";
import { useContext } from "react";
import { PmxActivityDetailsContext } from "../../contexts/pmxActivityDetailsProvider";

interface ExecutionJobProps {
  job: Job;
}
export function ExecutionGeneral(props: ExecutionJobProps) {
  const { activityId } = useContext(PmxActivityDetailsContext);
  const { job } = props;

  return (
    <div className="job-details mt-2 mb-3">
      <Card className="mb-3">
        <CardContent className="exeuction-heading">
          <Typography variant="h5" component="div">
            Job Details
          </Typography>
        </CardContent>
        <CardContent>
          <Row className="mb-3">
            <Col>
              <span className="execution-subtitle">Job ID</span>
              <br />
              {job?.id}
            </Col>
            <Col>
              <span className="execution-subtitle">Name</span>
              <br />
              {job?.name}
            </Col>
            <Col>
              <span className="execution-subtitle">Parent Job</span>
              <br />
              <Link
                to={`/activity/${activityId}/execution/${job?.parentJob?.id}`}
              >
                {job?.parentJob?.id}
              </Link>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col>
              <span className="execution-subtitle">Status</span>
              <br />
              {job?.status && getStatusIcon(job?.status)}
              {getStatusTitle(job?.status || "Loading")}
            </Col>
            <Col>
              <span className="execution-subtitle">Status Reason</span>
              <br />
              {job?.statusReason}
            </Col>
            <Col>
              <span className="execution-subtitle">Created At</span>
              <br />
              {job?.createdAt && convertLocalTimeZone(job?.createdAt)}
            </Col>
          </Row>
          <Row className="mb-3">
            <Col>
              <span className="execution-subtitle">Started At</span>
              <br />
              {job?.startedAt && convertLocalTimeZone(job?.startedAt as string)}
            </Col>
            <Col>
              <span className="execution-subtitle">Stopped At</span>
              <br />
              {job?.stoppedAt && convertLocalTimeZone(job?.stoppedAt as string)}
            </Col>
            <Col>
              <span className="execution-subtitle">Created By</span>
              <br />
              {job?.createdBy}
            </Col>
          </Row>
          <Row className="mb-3">
            <Col>
              <span className="execution-subtitle">Description</span>
              <br />
              {job?.description}
            </Col>
            {job?.environmentVariables &&
              job?.environmentVariables.length > 0 && (
                <Col>
                  <span className="execution-subtitle execution-environment-variable">
                    Environment Variables
                  </span>
                  <br />
                  {job?.environmentVariables.map((envVar: any) => (
                    <Chip
                      avatar={
                        <Chip
                          className="avatar-parameter"
                          color="primary"
                          label={envVar.name}
                        />
                      }
                      label={envVar.value}
                      key={"avatar-parameter" + envVar.name}
                    />
                  ))}
                </Col>
              )}
            <Col>
              <span className="execution-subtitle">
                Interactive Environment Bridge
              </span>
              <br />
              {job?.interactiveEnvironmentId ? "true" : "false"}
            </Col>
          </Row>

          {job?.commands && job?.commands.length > 0 && (
            <Row>
              <Col>
                <span className="execution-subtitle command">Command </span>
                <br />
                {job?.commands.map((command: String) => (
                  <b key={command}>{` ${command}`}</b>
                ))}
              </Col>
            </Row>
          )}
        </CardContent>
      </Card>
      <Card className="job-container-details">
        <CardContent className="exeuction-heading">
          <Typography variant="h5" component="div">
            Job Container
          </Typography>
        </CardContent>
        <CardContent>
          <Row className="mb-3">
            <Col>
              <span className="execution-subtitle">Working Directory</span>
              <br />
              <div id="job-working-directory">{job?.workingDir}</div>
            </Col>
            <Col>
              <span className="execution-subtitle">CPUs</span>
              <br />
              <div id="job-cpu">{job?.vCPU}</div>
            </Col>
            <Col>
              <span className="execution-subtitle">Memory</span>
              <br />
              <div id="job-memory">{job?.memory / 1000} GB</div>
            </Col>
            <Col>
              <span className="execution-subtitle">Nodes</span>
              <br />
              <div id="job-num-of-nodes">{job?.numOfNodes}</div>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col>
              <span className="execution-subtitle">Time Out</span>
              <br />
              {job?.timeout && job?.timeout / 86400} Day(s)
            </Col>
          </Row>
          {/* TODO: Add back in when releasing NONMEM */}
          {/* <Row className="mb-3">
            <Col>
              <Accordion>
                <Accordion.Item eventKey="0">
                  <Accordion.Header>Describe Patterns</Accordion.Header>
                  <Accordion.Body>
                    {job?.describePatterns &&
                      job?.describePatterns.map((pattern: String) => (
                        <div key={`${pattern}`}>
                          {pattern}
                          <br />
                        </div>
                      ))}
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>Ignore Patterns</Accordion.Header>
                  <Accordion.Body>
                    {job?.ignorePatterns &&
                      job?.ignorePatterns.map((pattern: String) => (
                        <div key={`${pattern}`}>
                          {pattern}
                          <br />
                        </div>
                      ))}
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                  <Accordion.Header>Parameters</Accordion.Header>
                  <Accordion.Body>
                    {job?.parameters &&
                      job?.parameters.map((parameter: Parameter) => (
                        <div key={`${parameter.name}@${parameter.value}`}>
                          {parameter.name}: {parameter.value}
                          <br />
                        </div>
                      ))}
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </Col>
          </Row> */}
        </CardContent>
      </Card>
    </div>
  );
}
